import axios from 'axios';

const anonymousUserIdKey = 'anonymousUserId';
const anonymousUserHeader = 'Anonymous-User-Id';

// Generate a random UUID
function generateUuid() {
    const uuidv4 = () => {
        const uuidKey = "10000000-1000-4000-8000-100000000000";
        return uuidKey.replace(/[018]/g, c =>
          // eslint-disable-next-line no-mixed-operators
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
      }
    // generate a random UUID
    const id = uuidv4();
    saveUserIdInStorage(id);
    return id;
}

// Set UUID header for anonymous user
function setUuidHeader(uuid) {
    axios.defaults.headers.common[anonymousUserHeader] = uuid;
}

// Get anonymous user Id from storage
export function getAnonymousUserIdFromStorage() {
    return localStorage.getItem(anonymousUserIdKey);
}

// Save anonymous user Id in storage for retrieval
function saveUserIdInStorage(id) {
    localStorage.setItem(anonymousUserIdKey, id);
}

// Generate anonymous user if not already stored, set UUID header
function setAnonymousUser() {
    const idFromStorage = getAnonymousUserIdFromStorage();
    const id = idFromStorage ? idFromStorage : generateUuid();
    setUuidHeader(id);
}

export default setAnonymousUser;
