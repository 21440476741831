import React, { useState } from 'react';

const PostComment = ({
    comment,
    likeComment,
    unlikeComment,
    commentDeleteable,
    deleteComment,
}) => {
    const [likes, setLikes] = useState(comment.likes);
    const [liked, setLiked] = useState(comment.liked);

    const toggleLike = () => {
        if (liked) {
            unlikeComment(comment.postCommentId);
            setLikes(likes - 1);
        } else {
            likeComment(comment.postCommentId);
            setLikes(likes + 1);
        }
        setLiked(!liked);
    }

    return (
        <div
            style={{
                // display: 'flex',
                borderStyle: 'double',
                padding: '10px',
                marginBottom: '20px',
            }}        
        >
            <div
                style={{ 
                    margin: '20px',
                    maxWidth: '500px',
                 }}
            >
                {comment.commentContent}
            </div>
            <div>
                created by: {comment.createdBy}
            </div>
            <div>
                created on: {comment.createdOn}
            </div>
            <div>
                { likeComment && unlikeComment ?
                        <button onClick={() => toggleLike()}>
                            {liked ? 'Unlike' : 'Like'}
                        </button>
                : null
            }
                <div>{likes} likes</div>
            </div>
            <div>
                {commentDeleteable ? (
                        <button onClick={() => deleteComment()}>
                            Delete
                        </button>
                    ) : null
                }
            </div>
        </div>
    );
};

export default PostComment;

