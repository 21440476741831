import axios from 'axios';
import {getAuth} from 'firebase/auth'
import { jwtDecode } from "jwt-decode";

axios.interceptors.request.use(
  async (config) => {
      let accessToken = localStorage.getItem('accessToken');
      const refreshThresholdSeconds = 120;
      if (!accessToken || jwtDecode(accessToken).exp < (Date.now() / 1000) - refreshThresholdSeconds) {
        accessToken = null;
        const user = getAuth().currentUser;
        if (user) {
          accessToken = await user.getIdToken(true);
          localStorage.setItem('accessToken', accessToken);
        }
      }
      if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

const BACKEND_API = process.env.REACT_APP_URI
  ? `${process.env.REACT_APP_URI}/api`
  : '/api';

export const getActivePosts = () =>
  axios.get(`${BACKEND_API}/post/active`);

  export const getPosts = () =>
  axios.get(`${BACKEND_API}/post`);

export const savePost = payload => 
    axios.post(`${BACKEND_API}/post`, payload);

export const updatePost = (postId, payload) => {
  return axios.put(
    `${BACKEND_API}/post/${postId}`,
    payload,
  );
};

export const deletePost = (postId,) => {
  return axios.delete(`${BACKEND_API}/post/${postId}`);
};

export const likePost = (postId) => {
  return axios.post(`${BACKEND_API}/post/${postId}/like`);
};

export const unlikePost = (postId) => {
  return axios.delete(`${BACKEND_API}/post/${postId}/like`);
};

export const getPostComments = (postId) => {
  return axios.get(`${BACKEND_API}/post/${postId}/comments`);
};

export const getPostCommentsApproved = (postId) => {
  return axios.get(`${BACKEND_API}/post/${postId}/comments/approved`);
};

export const savePostComment = (postId, payload) => {
  return axios.post(
    `${BACKEND_API}/post/${postId}/comment`, 
    payload,
  );
};

export const approvePostComment = (postCommentId) => {
  return axios.post(`${BACKEND_API}/post/comment/${postCommentId}/approval`);
};

export const unapprovePostComment = (postCommentId) => {
  return axios.delete(`${BACKEND_API}/post/comment/${postCommentId}/approval`);
};

export const deletePostComment = (postCommentId) => {
  return axios.delete(`${BACKEND_API}/post/comment/${postCommentId}`);
}

export const likePostComment = (postCommentId) => {
  return axios.post(`${BACKEND_API}/post/comment/${postCommentId}/like`);
};

export const unlikePostComment = (postCommentId) => {
  return axios.delete(`${BACKEND_API}/post/comment/${postCommentId}/like`);
};