import React, { useState } from 'react';
import PostComment from './PostComment';
import PostCommentEditor from './PostCommentEditor';

const Post = ({
    post,
    likePost,
    unlikePost,
    getPostCommentsApproved,
    saveComment,
    deleteComment,
    likePostComment,
    unlikePostComment,
}) => {
    const [likes, setLikes] = useState(post.likes);
    const [liked, setLiked] = useState(post.liked);
    const [comments, setComments] = useState([]);

    const toggleLike = () => {
        if (liked) {
            unlikePost(post.postId);
            setLikes(likes - 1);
        } else {
            likePost(post.postId);
            setLikes(likes + 1);
        }
        setLiked(!liked);
    }

    return (
        <div
            style={{
                borderStyle: 'double',
                padding: '20px',
                marginBottom: '20px',
                backgroundImage: 'url(images/backgrounds/image8.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }}
        >
            <h2
                style={{
                    marginTop: '0px',
                }}
            >{post.title}</h2>
            <div
                style={{ 
                    maxWidth: '800px',
                }}
                dangerouslySetInnerHTML={{ __html: post.content }}
            />
            <div
                style={{
                    display: 'flex',
                    // justifyContent: 'space-between',
                    marginTop: '20px',
                    marginBottom: '20px',
                }}
            >
                <button
                    style={{
                        marginRight: '10px',
                    }}
                    onClick={() => {
                    getPostCommentsApproved(post.postId).then(({data}) => {
                        setComments(data.postComments);
                    });
                }}>
                   {post.postCommentCount} Comments
                </button>
                <button
                    onClick={() => toggleLike()}
                    style={{
                        marginRight: '10px',
                    }}
                >
                    {liked ? 'Liked' : 'Like'} - {likes} likes
                </button>
            </div>
            <div
                style={{
                    maxWidth: '500px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderStyle: 'double',
                    padding: '20px',
                    marginBottom: '20px',
                }}
            >
                <PostCommentEditor
                    saveComment= {(comment) => {
                        saveComment(comment).then(({data}) => {
                            data['isCreater'] = true;
                            data['likes'] = 0;
                            data['liked'] = false;
                            setComments([
                                data,
                                ...comments,
                            ]);
                        });
                    }}
                />
            </div>
            <div
                style={{
                    maxWidth: '500px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: comments.length > 0 ? 'block' : 'none',
                    borderStyle: 'double',
                    padding: '20px',
                    marginBottom: '20px',
                }}
            >
                <div
                    style={{
                        marginBottom: '10px',
                    }} 
                >
                    Comments:
                </div>
                {comments.map(comment => (
                    <PostComment
                        key={comment.postCommentId}
                        likeComment={() => likePostComment(comment.postCommentId)}
                        unlikeComment={() => unlikePostComment(comment.postCommentId)}
                        comment={comment}
                        commentDeleteable = {comment.isCreater}
                        deleteComment={() => {
                            deleteComment(comment.postCommentId).then (() => {
                                getPostCommentsApproved(post.postId).then(({data}) => {
                                    setComments(data.postComments);
                                });
                            });
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Post;

