
import RichTextEdtor from '../components/RichTextEditor';
import React, { useState } from 'react';
import PostComment from './PostComment';

const PostEditor = ({
    post,
    updatePost,
    deletePost,
    getPostComments,
    deleteComment,
    approvePostComment,
    unapprovePostComment,
}) => {
    if (post && !post.publishOn) {
        const dateString = new Date().toUTCString()
        post.publishOn = dateString.slice(0, dateString.lastIndexOf(':'));
    }
    const [title, setTitle] = useState(post.title);
    const [publishOn, setPublishOn] = useState(post.publishOn);
    const [archive, setArchive] = useState(post.archived);
    const [comments, setComments] = useState([]);

    const setPostTitle = (title) => {
        setTitle(title);
        post.title = title;
    };
    const setPostPublishOn = (publishOn) => {
        setPublishOn(publishOn);
        post.publishOn = publishOn;
    }
    const togglePostArchive = () => {
        const toggledArchive = !archive;
        setArchive(toggledArchive);
        post.archived = toggledArchive;
        updatePost(post);
    }
    const setCommentApproval = (comment) => {
        if (comment.isApproved) {
            unapprovePostComment(comment.postCommentId);
        } else {
            approvePostComment(comment.postCommentId);
        }
        comment.isApproved = !comment.isApproved
        setComments([...comments]);
    }

    const titleTitle = 'Title: '
    const publishOnTitle = 'Publish On: '
    return (
        <div
            style={{
                marginBottom: '20px',
                borderStyle: 'double',
                padding: '20px',
                backgroundImage: 'url(images/backgrounds/image8.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }}
        >
            {titleTitle}
            <input
                type="text" 
                value={title}
                onChange={(e) => setPostTitle(e.target.value)}
            />
            <div style={{ 
                maxWidth: '800px',
            }}>
                <RichTextEdtor
                    content={JSON.parse(post.jsonContent)}
                    onUpdate={(html, json) => {
                        post.content = html;
                        post.jsonContent = JSON.stringify(json);
                    }}
                />
            </div>
            <div>
                {publishOnTitle}
                <input
                    type="text" 
                    value={publishOn}
                    onChange={(e) => setPostPublishOn(e.target.value)}
                />
            </div>
            <div style={{
                padding: "20px",
                display: "flex",
            }}>
                <button
                    style={{
                        width: "18%",
                        height: "50px",
                        marginLeft: "5%",
                        marginRight: "18%",
                    }}
                    onClick={() => updatePost(post)}
                >
                    Save
                </button>
                <button
                    style={{
                        width: "18%",
                        height: "50px",
                        marginRight: "18%",
                    }}
                    onClick={togglePostArchive}
                >
                    {archive ? 'Unarchive' : 'Archive'}
                </button>
                <button
                    style={{
                        width: "18%",
                        height: "50px",
                        marginRight: "5%",
                    }}
                    onClick={() => deletePost(post)}
                >
                    Delete
                </button>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: '20px',
                    marginBottom: '20px',
                }}
            >
                <button
                    style={{
                        marginRight: '10px',
                    }}
                    onClick={() => {
                    getPostComments(post.postId).then(({data}) => {
                        setComments(data.postComments);
                    });
                }}>
                   {post.postCommentCount} Comments
                </button>
                <div
                    style={{
                        marginRight: '10px',
                    }}
                >
                    {post.likes ? post.likes : 0} likes 
                </div>
                <div
                    style={{
                        marginRight: '10px',
                    }}
                >
                    {post.views ? post.views : 0} views
                </div>
            </div>
            <div
                style={{
                    maxWidth: '500px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderStyle: 'double',
                    padding: '20px',
                    marginBottom: '20px',
                    display: comments.length > 0 ? 'block' : 'none',
                }}
            >
                <div
                    style={{
                        marginBottom: '10px',
                    }} 
                >
                    Comments:
                </div>
                {comments.map((comment, index) => (
                    <div>
                        <PostComment
                            key={comment.postCommentId}
                            likeComment={null}
                            unlikeComment={null}
                            comment={comment}
                            commentDeleteable = {true}
                            deleteComment={() => {
                                deleteComment(comment.postCommentId).then (() => {
                                    getPostComments(post.postId).then(({data}) => {
                                        setComments(data.postComments);
                                    });
                                });
                            }}
                        />
                        <button onClick={() => setCommentApproval(comment)}>
                            {comment.isApproved ? "Unapprove" : "Approve"}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default PostEditor;

