import React, { useState } from 'react';

const PostComment = ({
    saveComment,
}) => {
const [comment, setComment] = useState('');

const submitComment = () => {
    const newComment = comment;
    setComment('');
    return saveComment(newComment)
};

    return (
        <div
            style={{
                // width: '100%',
            }}        
        >
            <div> Add a comment</div>
            <div 
                style={{ 
                    marginTop: '30px',
                    marginBottom: '30px',
                }}
            >
                <input
                    type="text" 
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            return submitComment();
                        }
                    }}
                    style={{
                        maxWidth: '500px',
                        // height: '30px',
                    }}
                />
                <button 
                    onClick={() => {
                        return submitComment();
                    }}
                    style={{
                        marginLeft: '10px',
                        maxWidth: '100px',
                        height: '30px',
                    }}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default PostComment;

