import React, { useState } from 'react';
import * as api from '../api/api';
import Post from './Post';

const Posts = () => {
    const [posts, setPosts] = useState(null);

    // todo change to posts not exist and not getting posts
    if (!posts) {
        api.getActivePosts().then(({data}) => {
            setPosts(data.posts);
        });
    }
    if (!posts) {
        return <div
            style={{
                fontSize: '64px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            Loading...
        </div>
        ;
    } else {
        return (
            <div>
                {posts.map((post, index) => (
                    <Post
                        key={index}
                        post={post}
                        likePost={api.likePost}
                        unlikePost={api.unlikePost}
                        likePostComment={api.likePostComment}
                        unlikePostComment={api.unlikePostComment}
                        getPostCommentsApproved={api.getPostCommentsApproved}
                        saveComment={(comment) => {
                            return api.savePostComment(
                                post.postId,
                                {commentContent: comment},
                            )
                        }}
                        deleteComment={api.deletePostComment}
                    />
                ))}
            </div>
        );
    }   
};

export default Posts;

