import React, { useState } from 'react';
import * as api from '../api/api';
import PostEditor from './PostEditor';


const PostsEditor = () => {
    const [posts, setPosts] = useState(null);

    const addPost = () => {
        const newPost = {
            title: '',
            content: '',
            jsonContent: '{}',
            publishOn: null,
        };
        api.savePost(newPost).then(({data}) => {
            setPosts([
                ...posts,
                data,
            ]);
        });
    }

    const updatePost = (post) => {
        api.updatePost(
            post.postId, 
            post
        ).then(() => {
            // console.log('updated post');
        });
    }

    const getPosts = () => {
        api.getPosts().then(({data}) => {
            setPosts(data.posts);
        });
    }

    const deletePost = (post) => {
        const postId = post.postId;
        api.deletePost(
            postId
        ).then(() => {
            setPosts(null);
            getPosts();
        });
    }
    // todo change to posts not exist and not getting posts
    if (!posts) {
        getPosts();
    }
    if (!posts) {
     return <div
            style={{
                fontSize: '64px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            Loading...
        </div>
        ;
    } else {
        const postsOrdered = posts.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1);
        return (
            <div>
                <button 
                    onClick={addPost}
                    style={{marginBottom: '10px'}}
                >
                    Add Post
                </button>
                {postsOrdered.map((post, index) => (
                    <PostEditor
                        key={post.postId}
                        post={post}
                        updatePost={updatePost}
                        deletePost={deletePost}
                        getPostComments={api.getPostComments}
                        approvePostComment={api.approvePostComment}
                        unapprovePostComment={api.unapprovePostComment}
                        deleteComment={api.deletePostComment}
                    />
                ))}
            </div>
        );
    }   
};

export default PostsEditor;

